/* Anke */
@font-face {
  font-family: 'Anke';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/anke.otf');
}

/* Proxima Nova */
@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/proximaNovaRegular.otf');
}
@font-face {
  font-family: 'Proxima Nova';
  font-style: bold;
  font-weight: 600;
  src: url('./assets/fonts/proximaNovaSemibold.otf');
}