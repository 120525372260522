.my-node-exit {
  opacity: 1;
}

.my-node-exit-active {
  opacity: 0;
  max-height: 0;
  transition: max-height 1000ms, opacity 1500ms;
}

.my-node-enter {
  opacity: 0;
  /* max-height: 0; */
}

.my-node-enter-active {
  opacity: 1;
  max-height: 1000px;
  transition: max-height 4000ms, opacity 1500ms;
}
